window.onscroll = function () {
    var header = document.querySelector('header');
    if (window.pageYOffset > 100) {
        header.classList.add("scrolldown");
    } else {
        header.classList.remove("scrolldown");
    }
};

const mailEinfuegen = () => {

    var mail_s = document.querySelector('#interview-mail-s')
    var mail_d = document.querySelector('#interview-mail-d')
    const part1 = "trachten"
    var part2 = Math.pow(2,6)
    var part3 = String.fromCharCode(part2)
    var part4 = "regionalkultur-Lausitz.de"
    var part5 = part1 + String.fromCharCode(part2) + part4
    if (mail_s !== null ) {
        mail_s.innerHTML = "<a href=" + "mai" + "lto" + ":" + part5 +">termin per mejlku</a>"
    }
    if (mail_d !== null) {
        mail_d.innerHTML = "<a href=" + "mai" + "lto" + ":" + part5 + ">termin per email</a>"
    }
}
mailEinfuegen()



//mail.addEventListener('click', insertMail, false)